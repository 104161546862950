import React from 'react'
import { Col, Button, ButtonGroup } from "react-bootstrap";

const Filters = ({ changeToId, filters }) => {

    return (
        <Col className="mapbtn">
            <ButtonGroup className="GroupBtn" >
                <Button onClick={() => changeToId("df35606b420b412c80b94bd6b3c2fe26")} variant={filters === "df35606b420b412c80b94bd6b3c2fe26" ? "dark" : "outline-dark"}>
                    <span> Espaces </span>
                </Button>
                <Button onClick={() => changeToId("a9ba4152d2e196e19841bad76bc3df20")} variant={filters === "a9ba4152d2e196e19841bad76bc3df20" ? "dark" : "outline-dark"}>
                    <span> Eco-Conception</span>
                </Button>
            </ButtonGroup>
        </Col>
    )
}

export default Filters;
