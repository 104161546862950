import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import {  Navigation, Thumbs } from "swiper";
// Import Swiper styles
import 'swiper/swiper.min.css';
import "./App.css"
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/thumbs/thumbs.min.css'
import CarbonTouch1 from "./CarbonTouch1";

const Gallery = ({ frontImg, gallery, setExtended, index,setIndex, isMobile }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiper, setSwiper] = useState(null);
    const [isTooltipDismissed, setisTooltipDismissed] = useState(sessionStorage.getItem("isTooltipDismissed"))
    const [lastClickedIndex, setlastClickedIndex] = useState(undefined)

    useEffect(() => {
        setTimeout(() => {
            sessionStorage.setItem("isTooltipDismissed", "true")
            setisTooltipDismissed("true")
        }, 10000)
    }, [])

    useEffect(() => {
        if (thumbsSwiper !== null) {
            thumbsSwiper.update(); 
            swiper.update()
            swiper.slideTo(index, 0, false);
    }});


    useEffect(() => {
        if(thumbsSwiper !== null){
            if(thumbsSwiper.clickedIndex !== lastClickedIndex && 4 < thumbsSwiper.clickedIndex ){
                console.log(thumbsSwiper.clickedIndex)
                setlastClickedIndex(thumbsSwiper.clickedIndex)
                const slidesRemaining =  thumbsSwiper.imagesLoaded - 1 - thumbsSwiper.clickedIndex
                console.log(slidesRemaining)
                if(slidesRemaining > 0 && slidesRemaining < 6) {
                    thumbsSwiper.setTranslate(thumbsSwiper.translate - (slidesRemaining * thumbsSwiper.width))
                }else if (slidesRemaining > 5){
                    thumbsSwiper.setTranslate(thumbsSwiper.translate - (5 * thumbsSwiper.width))
                }
            }
        }
    }, [index])

   /*  if (gallery.find(img => frontImg.fileId === img.fileId) === undefined) {
        gallery.unshift(frontImg)
    } */

    const extendGallery = () => {
        setExtended(true)
        setisTooltipDismissed("true")
        sessionStorage.setItem("isTooltipDismissed", "true")
    }

    return (
        <>
            {"true" !== isTooltipDismissed  && false === isMobile && 
            <div className="tooltips">
                <div className="OverlayGallery" >
                        <CarbonTouch1/>
                        Cliquer pour agrandir la galerie
                </div>
            </div>
            }
        <div className="gallery">
            <Swiper
                key={"SwiperPrincipal"}
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                    "--swiper-navigation-size": "33px"
                }}
                spaceBetween={0}
                navigation={!isMobile}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Thumbs]}
                loop={false}
                className="mySwiper2"
                onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
                onSwiper={setSwiper}
            >
                {gallery.map((image) => {
                    let key1 = image.fileId
                    return (
                            <SwiperSlide key={key1} >
                                <img
                                    onClick={() => { !isMobile && extendGallery()}}
                                    key={key1 + "img"}
                                    src={image.images.large}
                                    alt={image.alt}
                                />
                                {
                                    null !== image.alt &&
                                    <div className="complementsInfo">{image.alt}</div>
                                }
                            </SwiperSlide>
                    )
                })}
            </Swiper>
            <Swiper
                key={"SwiperThumb"}
                onSwiper={setThumbsSwiper}
                loop={false}
                spaceBetween={10}
                slidesPerView={6}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className="mySwiper"
            >
                {gallery.map((image) => {
                    let key2 = image.fileId + " Thumb"
                    return (
                        <SwiperSlide key={key2}>
                            <img
                                key={key2}
                                src={null === image.images.small ? image.images.large : image.images.small}
                                alt={image.alt}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
        </>
    );
};

export default Gallery