export const checkParamURL = () => {
    let parameterOfUrl = (new URL(window.location.href)).search

    if (parameterOfUrl.length !== 0) {
        parameterOfUrl = parameterOfUrl.slice(1)
        let splitedParameter = parameterOfUrl.split("&")
        return splitedParameter
    }
    return []
}

export const checkParamURLWithParameter = (parameterName) => {
    let parameterOfUrl = (new URL(window.location.href)).searchParams
    return parameterOfUrl.get(parameterName) ? parameterOfUrl.get(parameterName) : ""
}