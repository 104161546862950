import React, { useEffect } from 'react'
import InlineSVG from 'svg-inline-react';
import { MapSvg } from './SvgJs/map-source';

const Map = ({ listToDisplay, extendCard, selectedPlace, mapInitialised }) => {

    useEffect(() => {
        bindCircleEvent()
    })

    useEffect(() => {
        displaySelectedCircles();
        // Execute la fonction loadMap()
    }, [listToDisplay])

    useEffect(() => {
        extend(selectedPlace)
    }, [selectedPlace])


    /**  Fonction qui parcoure les circles de la map (map),
    *    et leur attribue un evenement onClick()
    */
    const bindCircleEvent = () => {
        let groupMapArray = Array.from(document.querySelectorAll("#map-vector #LEGENDES g"));
        groupMapArray.map(group => {
            group.onclick = () => extendCard(group.id);
            group.ontouchstart = () => extendCard(group.id)
            return null;
        })
    }
    /** Fonction qui parcoure les circles de la map (map),
    *    et anime celui qui est selectionné
    */
    const extend = object => {
        if (object.length === 0) {
            let allCircles = document.querySelectorAll('#map-vector #LEGENDES g circle');
            let circlesArray = Array.from(allCircles)
            circlesArray.map(circle => {
                circle.classList.remove('selected')
                return null;
            })

        } else {
            object = "place-" + object[0]["map-id"];
            let selectedCircle = document.querySelectorAll(`#map-vector #LEGENDES g#${object} circle`);
            // remove animation from others
            let allCircles = document.querySelectorAll('#map-vector #LEGENDES g circle');
            let circlesArray = Array.from(allCircles)
            circlesArray.map(circle => {
                circle.classList.remove('selected')
                return circle;
            })
            selectedCircle[0].classList.add('selected')
        }
    }

    /** Pour chaque on compare si il est présent dans la liste , 
    *   Si présent on lui attribue une classe (visible)
    *///JS pure
    const displaySelectedCircles = () => {
        let VirtualMap = Array.from(document.querySelectorAll("#map-vector #LEGENDES g"));
        VirtualMap.map((group, index) => {
            if (listToDisplay.some(place => `place-${place["map-id"]}` === group.id)) {
                group.classList.add('visible')
            } else {
                group.classList.remove('visible')
            }
            if (index === (VirtualMap.length - 1)) {
                mapInitialised()
            }
            return null;
        })

    }

    return (
        <InlineSVG src={MapSvg} className="map" />
    )
}

export default Map