import React, { useContext } from "react"
import parse from "html-react-parser"
import './App.css';
import { isMobileContext } from "./App";
import defaultImg from "./images/default.jpg"

function Cards({ place, extendCard, nbr, outHover, hover }) {
    const isMobile = useContext(isMobileContext)
    return (

        <div style={{ animationDelay: (nbr * 50) + 'ms' }} className={!isMobile ? "card-place" : "card-place-mobile"} onClick={() => {
            extendCard(place["map-id"]);
        }}>
            {
                !isMobile &&
                <div className="card-place-desktop" onMouseEnter={()=> hover(place["map-id"])} onMouseLeave={()=> outHover(place["map-id"])}>
                    <img key={parse(place.slug)} className="cards-img-desktop" src={true === place.hasOwnProperty("media") ? place.media.url : defaultImg} alt={parse(place.slug)} />
                    <div>
                        <h5 key={parse(place.name)}>
                            {parse(place.name)}
                        </h5>
                        <div key={parse(place.baseline)}>
                            {parse(place.baseline)}
                        </div>
                    </div>
                </div> 
                 }
            {
                isMobile &&
                (
                    <div className="card-mobile" >
                        {
                            place.hasOwnProperty("media") ?

                                <img alt="" key={parse(place.slug)} className="cards-img" src={place.media.url} />
                                :
                                <img key={parse(place.slug)} className="cards-img" src={defaultImg} alt="default"></img>
                        }
                        <h6 key={parse(place.name)} className="mobile-body">
                            {parse(place.name)}
                        </h6>
                    </div>
                )
            }
        </div>
    )
}
export default Cards