export const getParam = (arrayOfParam, paramToGet) => {
    if (arrayOfParam.length !== 0) {
        let parameterGotten = arrayOfParam.filter((param) => {
            if (param.includes(paramToGet)) {
                return param
            }
        })
        if (parameterGotten.length === 0) return []
        parameterGotten = (parameterGotten[0]).split("=")

        return parameterGotten[1]
    }
    return []
}