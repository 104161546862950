import React, { useContext, useEffect, useRef } from "react"
import {CSSTransition, SwitchTransition} from "react-transition-group";

import 'bootstrap/dist/css/bootstrap.min.css'
import Cards from "./Card.js";
import ExtendedCard from "./extendedCard.js";
import './App.css';
import { isMobileContext } from "./App.js";
import closeImg from "./images/close.svg"
import { MapMobile } from "./MapMobile.js";


function Aside({ filters, listToDisplay, backToList, isExtended, goExtendCard, selectedPlace, hover, outHover }) {

    const isMobile = useContext(isMobileContext)
    const nodeRef = useRef(null)
    //gerer les classname avec un objet
    const classname = {
        mobile: "list-card",
        mobileVisible: "list-card",
        desktopSpaces: "aside columns-card-spaces",
        desktopExpe: "aside columns-card-Expe",
    }

    useEffect(() => {
        if (!isExtended) {
            const divPlaces = document.querySelector(".aside-panel");
            let ScrollToPosY = localStorage.getItem('scrollHistory')
            divPlaces.scrollTo({ top: ScrollToPosY });

        } else {
            const divPlaces = document.querySelector(".aside-panel");
            divPlaces.scrollTo(0, 0)
        }
    }, [isExtended])
    return (
        <>
            {
                <SwitchTransition>
                    <CSSTransition
                        key={isExtended}
                        nodeRef={nodeRef}
                        addEndListener={done => {
                            nodeRef.current.addEventListener("transitionend", done, false);
                        }}
                        classNames="list-card">
                        <div ref={nodeRef}>
                        {
                            false === isExtended ?
                                <div id="List-places" className={!isMobile ? (filters === "df35606b420b412c80b94bd6b3c2fe26" ? classname.desktopSpaces : classname.desktopExpe)  :  classname.mobileVisible}>
                                    {
                                        listToDisplay.map((item, index) => {
                                            return (
                                                <Cards outHover={outHover} hover={hover} nbr={index} key={item["map-id"]} place={item} extendCard={goExtendCard} />
                                            )
                                        })
                                    }
                                    {
                                        isMobile &&
                                        <MapMobile isExtended={isExtended} listToDisplay={listToDisplay} mapId={selectedPlace}/>
                                    }
                                </div>
                            :
                                <div className={!isMobile ? "aside aside-panel" : "mobile-anim"}>
                                    {
                                        isMobile
                                        && <MapMobile isExtended={isExtended} listToDisplay={listToDisplay} mapId={selectedPlace}/>
                                    }
                                    <ExtendedCard key={selectedPlace.id} place={selectedPlace} isMobile={isMobile}  />
                                    {
                                        !isMobile ?
                                        <img onClick={backToList} key={'imageId'} className="close" src={closeImg} alt="Fermer" />
                                        :
                                        <div onClick={backToList} className="closeMobile">
                                            <img  key={'imageId'}  src={closeImg} alt="Fermer" />
                                        </div>
                                    }
                                </div>
                        }
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            }        
        </>
    )
}

export default Aside;
