import React from 'react'
import logoBlack from "./images/logo-thecamp-cip.png"

const MobileFilter = ({ mobileFilterDisplayed, changeMobileFilter, filters, changeToId }) => {

    return (
        <div className={!mobileFilterDisplayed ? "mobile-menu" : "mobile-menu opened"}>
            <div className="logo-mobile">
                <a href="/">
                    <img src={logoBlack} alt="Logo" />
                </a>
            </div>
            <ul className="menu">
                <li data-id="pratical_info" className={filters === "df35606b420b412c80b94bd6b3c2fe26" ? "active" : ""} onClick={() => {
                    changeToId("df35606b420b412c80b94bd6b3c2fe26");
                    changeMobileFilter(false);
                }}>
                    <span>Espaces</span>
                </li>
                <li data-id="experimentation" className={filters === "df35606b420b412c80b94bd6b3c2fe26" ? "" : "active"} onClick={() => {
                    changeToId("a9ba4152d2e196e19841bad76bc3df20");
                    changeMobileFilter(false)
                }}>
                    <span>Eco-Conception</span>
                </li>
            </ul>
        </div>
    )
}

export default MobileFilter
