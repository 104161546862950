import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import {  Navigation, Thumbs } from "swiper";
// Import Swiper styles
import 'swiper/swiper.min.css';
import "./App.css"
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/thumbs/thumbs.min.css'
import { Modal } from "react-bootstrap";
import closeImg from "./images/close.svg"

const GalleryExtend = ({gallery, frontImg, setExtended, index,setIndex,isMobile }) => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (thumbsSwiper !== null) {
            thumbsSwiper.update();
            swiper.update()
            swiper.slideTo(index, 0, false);
    }});

    /* if (gallery.find(img => frontImg.fileId === img.fileId) === undefined) {
        gallery.unshift(frontImg)
    } */

  return (
    <Modal
        className="modalGallery"
        fullscreen={true}
        show={true}
        onHide={() => setExtended(false)}
    >
        <Modal.Body className="p-0">

            <Swiper
                key={"SwiperPrincipal"}
                style={{
                    "--swiper-navigation-color": "#FFF",
                    "--swiper-pagination-color": "#FFF"                    
                }}
                onSwiper={setSwiper}
                spaceBetween={0}
                navigation={!isMobile}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Thumbs]}
                loop={false}
                className="mySwiperExtend2"
                onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
            >

                {gallery.map((image) => {
                    let key1 = image.fileId
                    return (
                        <SwiperSlide key={key1} >
                            <figure>
                                <img
                                    className="extendFromGallery"
                                    key={key1 + "imgFullScreen"}
                                    src={image.images.large}
                                    alt={image.alt}
                                />
                                {
                                    null !== image.alt &&
                                    <figcaption className="complementsInfoExtend">{image.alt}</figcaption>
                                }
                            </figure>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <img onClick={() => setExtended(false)} className="closeExtend" src={closeImg} alt="Fermer" />
            <Swiper
                key={"SwiperThumb"}
                onSwiper={setThumbsSwiper}
                loop={false}
                spaceBetween={10}
                slidesPerView={gallery.length < 10 ? 10 : gallery.length}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className="mySwiperExtend"
            >
                {gallery.map((image) => {
                    let key2 = image.fileId + " ThumbFullScreen"
                    return (
                        <SwiperSlide key={key2}>
                            
                            <img
                                key={key2}
                                src={null === image.images.small ? image.images.large : image.images.small}
                                alt={image.alt}
                            />
                            
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Modal.Body>
    </Modal>
  )
}

export default GalleryExtend