import React from "react"
import { Col, Container, Row, Accordion } from "react-bootstrap";
import parse from "html-react-parser"
import "./App.css"
import InlineSVG from 'svg-inline-react';
import { VrSvg } from './SvgJs/Vr-source'
import GalleryContainer from "./GalleryContainer";
import { MapMobile } from "./MapMobile";

function ExtendedCard({ place, isMobile }) {

    return (
        <>
            {
                place.length > 0 ?
                    <div className={ false === isMobile ? "imgExtendDesktop" : "imgExtendMobile px-0" }>
                        <Col>
                            <Row>
                                <div className="extendedBody">
                                    <h1>
                                        {parse(place[0].name)}
                                    </h1>
                                    <h5 className="subtitle text-uppercase">
                                        {parse(place[0].baseline)}
                                        <br />
                                    </h5>
                                    <div className="MediaWrapper">
                                        {
                                            place[0].hasOwnProperty("media") && place[0].hasOwnProperty("galerie") ?
                                                <GalleryContainer frontImg={place[0].media} gallery={place[0]["galerie"]} />
                                                : place[0].hasOwnProperty("media") ? <img className="media-header" alt="rendu" src={place[0].media.url} /> : <br />
                                        }
                                        {
                                            place[0].hasOwnProperty("media-video") &&
                                            <div className="VideoWrapper">
                                                {parse(place[0]["media-video"].metadata.html)}
                                            </div>

                                        }
                                    </div>

                                    {
                                        place[0].hasOwnProperty("lien-vr") &&
                                        <div className="placesTools">
                                            {
                                                place[0].hasOwnProperty("lien-vr") ? (<><a className="vrlink" href={place[0]["lien-vr"]} target="_blank" rel="noreferrer"><InlineSVG src={VrSvg} /><span>Vue 360° </span></a></>) : <br />
                                            }
                                        </div>
                                    }
                                    <div>
                                        {
                                            ((place[0]["horaires-d-ouverture"] !== "" && place[0].hasOwnProperty("horaires-d-ouverture")) || (place[0]["informations-techniques"] !== "" && place[0].hasOwnProperty("informations-techniques"))) ? (
                                                <>
                                                    <Accordion className="accordion-div" flush>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header className="collapse-head" >Infos pratiques</Accordion.Header>
                                                            <Accordion.Body className="collapse-bot">
                                                                {place[0].hasOwnProperty("horaires-d-ouverture") ? place[0]['horaires-d-ouverture'] !== "" ? parse(place[0]["horaires-d-ouverture"]) : "" : ""}
                                                                {place[0].hasOwnProperty("informations-techniques") ? place[0]['informations-techniques'] !== "" ? parse(place[0]["informations-techniques"]) : "" : ""}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                    <br />
                                                </>)
                                                : <>
                                                </>
                                        }
                                    </div>
                                    <div>
                                        {parse(place[0].description)}
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </div>
                    :
                    <>
                    </>
            }
        </>
    )
}

export default ExtendedCard;