export const VrSvg = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="28px" height="16px" viewBox="0 0 28 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun_vr_2619190" fill="#000" fill-rule="nonzero">
            <path d="M27,0 L1,0 C0.44771525,0 0,0.44771525 0,1 L0,15 C0,15.5522847 0.44771525,16 1,16 L11,16 C11.4921797,16.0071559 11.9163726,15.6550758 12,15.17 L12.66,11.17 C12.7543178,10.5014452 13.3248331,10.0033088 14,10 C14.7099665,10.0076041 15.298301,10.5526787 15.36,11.26 L16,15.17 C16.0836274,15.6550758 16.5078203,16.0071559 17,16 L27,16 C27.5522847,16 28,15.5522847 28,15 L28,1 C28,0.44771525 27.5522847,0 27,0 Z M6.5,11 C4.56700338,11 3,9.43299662 3,7.5 C3,5.56700338 4.56700338,4 6.5,4 C8.43299662,4 10,5.56700338 10,7.5 C10,9.43299662 8.43299662,11 6.5,11 L6.5,11 Z M21.5,11 C19.5670034,11 18,9.43299662 18,7.5 C18,5.56700338 19.5670034,4 21.5,4 C23.4329966,4 25,5.56700338 25,7.5 C25,9.43299662 23.4329966,11 21.5,11 L21.5,11 Z" id="Shape"></path>
        </g>
    </g>
</svg>`