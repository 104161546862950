import React, { useEffect } from 'react'
import InlineSVG from 'svg-inline-react';
import { MapSvg } from './SvgJs/map-source';

export const MapMobile = ({ listToDisplay, mapId, isExtended }) => {

    useEffect(() => {
        ZoomToPlace(mapId)
        extend(mapId)
    }, [mapId])

    useEffect(() => {
        displaySelectedCircles()
    }, [listToDisplay])

    function ZoomToPlace(mapId)
	{	   
        if (undefined != mapId && 0 !== mapId.length) { 
            document.querySelector("#Layer").style.removeProperty("transform");
            mapId = "place-" + mapId[0]["map-id"];
            let scale = 2;
            
            let placePositionX = document.querySelector(`g#${mapId} circle`).getAttribute("cx");
            let placePositionY = document.querySelector(`g#${mapId} circle`).getAttribute("cy");
            document.querySelector("#Layer").setAttribute("transform", ` translate(${window.innerWidth*1.5},${window.innerHeight/2})scale(${scale}) translate(${-placePositionX }, ${(-placePositionY)})`);
        }else {
            document.querySelector("#Layer").style.transform = "scale(1.3) translate(-15%,-0%)";
            document.querySelector("#Layer").removeAttribute("transform");
        }
    }
    
    const extend = mapId => {
        if (undefined != mapId && mapId.length === 0) {
            let allCircles = document.querySelectorAll('#map-vector #LEGENDES g');
            let circlesArray = Array.from(allCircles)
            circlesArray.map(circle => {
                circle.classList.remove('selected')
                return null;
            })
            displaySelectedCircles()
        } else {
            let object = "place-" + mapId[0]["map-id"];
            let selectedCircle = document.querySelectorAll(`#map-vector #LEGENDES g#${object}`);
            // remove animation from others
            let allCircles = document.querySelectorAll('#map-vector #LEGENDES g');
            let circlesArray = Array.from(allCircles)
            circlesArray.map(circle => {
                circle.classList.remove('visible')
                return circle;
            })
            selectedCircle[0].classList.remove('visible')
            selectedCircle[0].classList.add('selected')
        }
    }

    const displaySelectedCircles = () => {
        let VirtualMap = Array.from(document.querySelectorAll("#map-vector #LEGENDES g"));
        if (mapId.length === 0) {
        VirtualMap.map((group, index) => {
            if (listToDisplay.some(place => `place-${place["map-id"]}` === group.id)) {
                group.classList.add('visible')
            } else {
                group.classList.remove('visible')
            }
            return null;
        })
    } else {
        VirtualMap.map((group, index) => {
            if (listToDisplay.some(place => `place-${mapId}` === group.id)) {
                group.classList.add('visible')
            } else {
                group.classList.remove('visible')
            }
            return null;
        })
    }

    }


  return (
    <InlineSVG src={MapSvg} className="mapMobile" />   
  )
}


//getAttribute("cx")