import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import logoMobile from "./images/galet-thecamp-white.svg"

export default function TopNav({filters, mobileFilterDisplayed, changeMobileFilter}) {
    return (
        <div className="mobile-top-nav">
            <div className="top-nav-content">
                <Container fluid className="px-0">
                    <Row className="top-nav mx-0">
                        <Col xs={3} className="logo-mobile ">
                            <img src={logoMobile} alt="logo thecamp"/>
                        </Col>
                        <Col xs={7} className="filter">
                            {filters === "df35606b420b412c80b94bd6b3c2fe26" ? "Espaces" : "Eco-Conception"}
                        </Col>
                        <Col xs={2} className="menu-trigger h-100 d-flex align-items-center justify-content-end" onClick={() => {changeMobileFilter(!mobileFilterDisplayed)}}>
                            <div className="menu-trigger-content d-flex align-items-center justify-content-center">
                                <div className={!mobileFilterDisplayed ? "menu-icon" : "menu-icon active"}>
                                </div>                                    
                            </div>
                        </Col>
                    </Row> 
                </Container>
            </div>
        </div>
    )
}
