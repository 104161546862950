import './App.css';
import React, { useState, useEffect, useRef } from "react";
import * as dotenv from "dotenv";
import Aside from './Aside';
import { Col, Container, Row } from "react-bootstrap";
import Filters from './Filters';

import Map from './Map';
import loaderImg from "./images/loader.svg"
import mustRotateImg from "./images/must-rotate.svg"
import logoTc from "./images/label-thecamp-DB.png"
import TopNav from './TopNav.js';

import MobileFilter from './MobileFilter';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { fetchData } from './services/api/FetchData';
import { sortItems } from './utils/SortItems';
import { checkParamURL, checkParamURLWithParameter } from './utils/checkParamURL'
import { getParam } from './utils/getParam';

export const ModePanelContext = React.createContext();
export const isMobileContext = React.createContext();

dotenv.config();
const endpoint = process.env.REACT_APP_API_ENDPOINT;

function App() {

    const scrolRef = useRef()

    const [loading, setloading] = useState(true)

    const [isMobile, setIsMobile] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)
    const [isMobileFilter, setisMobileFilter] = useState(false)

    const [placesFetched, setplacesFetched] = useState([])
    const [modeList, setModeList] = useState("")
    const [listeFiltered, setListeFiltered] = useState([])

    const [isExtendedCard, setIsExtendedCard] = useState(false)
    const [selectedPlace, setSelectedPlace] = useState([])

    const hover = (Id) => {
        document.getElementById(`place-${Id}`).firstElementChild.classList.add('hovered')
    }
    const outHover = (Id) => {
        document.getElementById(`place-${Id}`).firstElementChild.classList.remove('hovered')
    }

    window.addEventListener('resize', () =>
    { 
        if (width < 992 && window.innerWidth > 992) {
            setloading(true)
            window.location.reload()
        } else if (width > 992 && window.innerWidth < 992) {
            setloading(true)
            window.location.reload()
        } else {
            setWidth(window.innerWidth)
        }
    });

    useEffect(() => {
        if (width < 992) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    useEffect(() => {
        if (placesFetched.length !== 0) {
            returnToList();
            filterPlaces();
        }
    }, [modeList ])

    useEffect(() => {
        displayUrlPlaces()
    }, [loading])

    useEffect(() => {
        load()
        return () => {
            localStorage.clear()
        }
    }, [])


    const filterPlaces = () => {
        let filteredPlaces = placesFetched.filter((place) => {
            if (place.hasOwnProperty("layer")) {
                return place.layer === modeList;
            }
        });
        setListeFiltered(filteredPlaces)
    }


    const mapInitialised = () => {
        localStorage.setItem('scrollHistory', 0)
        setTimeout(() => {
            setWidth(window.innerWidth)
        }, 1000)
    }

    const displayUrlPlaces = () => {
        let placeParam = checkParamURLWithParameter("place")
        if (placeParam.length !== 0) {
            extendByURL(placeParam)
        }
    }
    const load = async () => {
        try {
            const data = await fetchData(endpoint)
            const sortedPlaces = sortItems(data)
            setplacesFetched(sortedPlaces)
            let parameters = checkParamURL()
            if (getParam(parameters, "filter").length !== 0) changeToFilter(getParam(parameters, "filter")); else changeToFilter("espaces")
            setloading(false)
        } catch (error) {
            setloading(true)
            alert("Le serveur distant est injoignable veuillez réessayer ultérieuremnt")
            console.log(error)
        }
    }

    const changeToId = (id) => {
        if (id === modeList) {
        } else {
            setListeFiltered([])
            setModeList(id)
        }
    }

    const changeToFilter = filter => {
        if (filter === "experimentations") {
            changeToId("a9ba4152d2e196e19841bad76bc3df20")
        } else if (filter === "espaces") {
            changeToId("df35606b420b412c80b94bd6b3c2fe26")
        } else {
            changeToId("df35606b420b412c80b94bd6b3c2fe26")
        }
    }

    const extendByURL = placeName => {
        let filterPlace = placesFetched.filter((filtre) => {
            if (filtre["map-id"] === placeName) {
                return filtre;
            }
        });
        if (filterPlace.length === 0) return;
        setSelectedPlace(filterPlace);
        setIsExtendedCard(true)
    }

    const extendsCard = cardId => {
        setSelectedPlace([])
        if (cardId.includes("place")) {
            cardId = cardId.slice(6);
        }
        if ( false === isMobile) {
            document.getElementById(`place-${cardId}`).firstElementChild.removeAttribute('class')
        }
        let filterPlace = listeFiltered.filter((filtre) => {
            if (filtre["map-id"] === cardId) {
                return filtre;
            }else{
                document.getElementById(`place-${filtre["map-id"]}`).firstElementChild.classList.remove('hovered')
            }
        });

        if (filterPlace.length === 0) return;
        setSelectedPlace(filterPlace);
        setIsExtendedCard(true);
        saveScrollPosition()
    }

    const saveScrollPosition = () => {
        if (isExtendedCard === false) {
            scrolRef.current.recalculate();
            localStorage.setItem('scrollHistory', scrolRef.current.getScrollElement().scrollTop)
        }
        setTimeout(() => {
            scrolRef.current.recalculate();
            scrolRef.current.getScrollElement().scrollTop = 0;
        }, 500)
    }

    const returnToList = () => {
        setIsExtendedCard(false)
        setSelectedPlace([])
        scrolRef.current.recalculate();
        false === isMobile ?
        setTimeout( () => {
            scrolRef.current.getScrollElement().scrollTo(localStorage.getItem('scrollHistory'), (localStorage.getItem('scrollHistory')))
            localStorage.setItem('scrollHistory', 0)
        }, 220)
        :
        setTimeout( () => {
            scrolRef.current.getScrollElement().scrollTo(localStorage.getItem('scrollHistory'), (localStorage.getItem('scrollHistory')))
            localStorage.setItem('scrollHistory', 0)
        }, 500)

    }

    return (
        <>
            <isMobileContext.Provider value={isMobile}>
                <Container fluid>
                    
                    <div className="must-rotate">
                        <p>veuillez tournez votre t&#233;l&#233;phone</p>
                        <img alt="Must rotate your device" src={mustRotateImg}/>
                    </div>
                    {
                        loading &&
                        <div className="loader">
                            <img alt="loader" src={loaderImg} />
                        </div>
                    }
                    {
                        !isMobile &&
                        <a href="/"> <img src={logoTc} className="logo" alt="Logo" /></a>
                    }
                    <Row>
                        {
                            isMobile &&
                            <>
                                <TopNav mobileFilterDisplayed={isMobileFilter} changeMobileFilter={setisMobileFilter} filters={modeList} />
                                <MobileFilter mobileFilterDisplayed={isMobileFilter} changeToId={changeToId} filters={modeList} changeMobileFilter={setisMobileFilter} />
                            </>
                        }
                        <Col xs="12" lg="8" className="px-0">
                            {
                                !isMobile &&
                                <>
                                    <Map isExtended={isExtendedCard} listToDisplay={listeFiltered} extendCard={extendsCard} selectedPlace={selectedPlace} mapInitialised={mapInitialised} />
                                    <Filters changeToId={changeToId} filters={modeList} />
                                </>
                            }
                        </Col>
                        <Col xs="12" lg="4" className={`aside-panel ${((isMobile)) ? !isExtendedCard ? "mobile-visible px-0" : "extended-mobiles mt-0 px-0 mobile-visible " : "separation px-0" }`}>
                            <SimpleBar style={{ height: "100vh" }} autoHide={true} forceVisible="n" timeout="400" ref={scrolRef}>
                                <Aside filters={modeList} listToDisplay={listeFiltered} backToList={returnToList} isExtended={isExtendedCard} goExtendCard={extendsCard} selectedPlace={selectedPlace} hover={hover} outHover={outHover} />
                            </SimpleBar>
                        </Col>
                    </Row>
                </Container>
            </isMobileContext.Provider>
        </>
    )
}
export default App;