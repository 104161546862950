import React, { useContext ,useState } from 'react'
import Gallery from './Gallery'
import GalleryExtend from './GalleryExtend';
import { isMobileContext } from "./App.js";



const GalleryContainer = ({frontImg,gallery}) => {
  const isMobile = useContext(isMobileContext)

  const [isModalDisplayed, setisModalDisplayed] = useState(false);
  const [indexOfSwiper, setindexOfSwiper] = useState(0)
  return (
    <div className="GalleryContainer">
        {
            !isModalDisplayed ?
            <Gallery isMobile={isMobile} index={indexOfSwiper} setIndex={setindexOfSwiper} frontImg={frontImg} gallery={gallery} setExtended={setisModalDisplayed} />
            :
            <GalleryExtend isMobile={isMobile} index={indexOfSwiper} setIndex={setindexOfSwiper} gallery={gallery} frontImg={frontImg} setExtended={setisModalDisplayed} isExtended={isModalDisplayed}/>
        }
    </div>
  )
}

export default GalleryContainer